<template>
  <div class="">
    <el-table
      border
      :row-style="{ height: '45px' }"
      :data="list"
      @row-click="rowClickFirstTable"
      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      :row-class-name="setTableRowClassName"
    >
      <el-table-column prop="org_code_no" label="企业名称"></el-table-column>
      <el-table-column prop="type" label="资质证书类型"></el-table-column>
      <el-table-column prop="name" label="资质证书名称"></el-table-column>
      <el-table-column prop="expiry_date" label="有效期"></el-table-column>
      <el-table-column prop="added_by" label="提交人"></el-table-column>
      <el-table-column prop="approved_by" label="审核人"></el-table-column>
      <el-table-column prop="photo" label="资质证书照片"></el-table-column>
      <el-table-column prop="status" label="证书在本系统状态"></el-table-column>
      <el-table-column min-width="200" label="操作">
        <template #default="{ row }">
          <el-button type="info" class="but" @click="handleDetails(row)"
            >审核</el-button
          >
          <el-button type="danger" class="but" @click="handleDelete(row)"
            >驳回</el-button
          >
          <el-button type="danger" class="but" @click="handleDelete(row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import * as mzyqualification_attachments from "@/api/mzyqualification_attachments";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      mzyqualification_attachments.getList(this.listQuery).then((response) => {
        this.list = response.data;
        response.columnFields.forEach((item) => {
          // 首字母小写
          item.columnName =
            item.columnName.substring(0, 1).toLowerCase() +
            item.columnName.substring(1);
        });
        this.headerList = response.columnFields;
        this.total = response.count;
        this.listLoading = false;
      });
    },
    // 设置行的类名
    setTableRowClassName({ row, rowIndex }) {
      console.log(rowIndex);
      return row === this.selectedRow ? "selected-row" : "";
    },
    //行的点击事件
    rowClickFirstTable(row, column, event) {
      console.log(column);
      console.log(event);
      // 点击行
      this.radio = row.id;
      this.secondQuery.page = 1;
      this.secondQuery.limit = 10;
      //   this.querySecondList(this.radio);
      this.showTitleDetail(row);
      this.selectedRow = row;
    },
  },
};
</script>
<style lang="scss">
.nomal-form label {
  font-weight: 500 !important;
}

.demo-card .el-card__header {
  padding: 10px !important;
  line-height: 1;
  position: relative;
}

.el-card__body {
  padding: 10px 10px 0 10px !important;
  height: 100%;
}
.form-card .el-card__body {
  padding-right: 0 !important;
}
.form-card .el-form {
  padding-right: 5px;
}

.dialog-small .el-dialog__header {
  padding: 10px;
  border-bottom: 1px solid #efefef;
}

.dialog-small .el-dialog__headerbtn {
  top: 15px;
}

.dialog-small .el-dialog__body {
  padding: 10px;
}

.el-form-item__error {
  padding-top: 0;
}
.edit-buttons {
  position: absolute !important;
  top: 2px;
  right: 10px;
}
.blue-row {
  background-color: blue;
}
.selected-row {
  //选中行的样式
  background-color: #f39907 !important;
  color: #fff !important;
  transition: background-color 0.2s ease-in-out !important;
}

// 取消表格鼠标进入高亮显示
.el-table__row:hover > td {
  background-color: transparent;
}
.but {
  height: 20px;
  line-height: 0px !important;
}
.form-item {
  display: inline-block;
  padding-right: 10px;
}
.sst {
  margin-bottom: 20px;
}
.form-item {
  padding-bottom: 10px;
}
.form-item > label {
  font-size: 12px;
}
</style>